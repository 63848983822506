@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400&display=swap);

body {
  font-family: 'Roboto Mono', monospace;
  padding: 20px;
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.headerImage {
  height: 50px;
  padding-left: 5px;
}

.container {
  width: 100%;
  display: flex;
}

.lge {
  flex: 2;
  border-right: solid 1px black;
  padding: 20px;
}

.referral {
  flex: 1;
  border-left: solid 1px black;
  padding: 20px;
}

.cardHeader {
  width: 100%;
  text-align: center;
  font-size: xx-large;
}

@media (max-width: 900px) {
  .container {
    flex-direction: column;
  }

  .header {
    flex-direction: column;
  }
}